import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import loadable from '@loadable/component';

import AccountLayout from '../../layouts/accountLayout';
import PrivateRoute from '../../components/PrivateRoute';
import { Dashboard } from '../../components/Dashboard';
import {
  getUserSubscription,
  updateSubscription,
  cancelSubscription,
} from '../../services/subscription.api';
import {
  getAllPets,
  updatePet,
  createPet,
  deletePet,
} from '../../services/pets.api';
import { getProductPrice, getProducts } from '../../services/products.api';
import { Subscription, Pet, Product } from '../../services/models';
import { formatDate } from '../../utils';
import { isLoggedIn } from '../../services/auth.api';
import { TitleComponent } from 'components/TitleComponent';
import { graphql } from 'gatsby';

const Orders = loadable(() => import('./orders'));
const AccountInfo = loadable(() => import('./jInfo'));
const Refer = loadable(() => import('./refer'));
const ResetPasswordConfirm = loadable(() =>
  import('components/ResetPasswordConfirm'),
);
const EditPet = loadable(() => import('components/Dashboard/Pets/EditPet'));
const AddPet = loadable(() => import('components/Dashboard/Pets/AddPet'));
const DashboardHome = loadable(() => import('components/Dashboard'));
const ManageDeliveries = loadable(() =>
  import('components/Dashboard/ManageDeliveries'),
);
const ManageSubscription = loadable(() =>
  import('components/Dashboard/ManageSubscription'),
);
const LoadAfterContext = loadable(() => import('components/LoadAfterContext'));

interface SubscriptionContextState {
  subscriptions: Subscription[];
  pets: Pet[];
  productPrice: number;
  isContextLoaded: boolean;
  fetchSubscriptions?: Function;
  resumeSubscription?: Function;
  updateSubscriptionData?: Function;
  cancelSubscriptionFunc?: Function;
  updatePetData?: Function;
  addPet?: Function;
  removePet?: Function;
  getSubscriptionById?: Function;
  getSubscriptionByPetId?: Function;
  getPetBySubscriptionId?: Function;
  getPetById?: Function;
  allProducts: Product[];
  fetchPets?: Function;
}

interface LoaderContextState {
  showPageLoader: boolean;
  setShowPageLoader: Function;
}

const LoaderContext = React.createContext({} as LoaderContextState);
const initialState = {
  subscriptions: [],
  pets: [],
  productPrice: 0,
  isContextLoaded: false,
  selectedSubscription: {},
  allProducts: [],
} as SubscriptionContextState;

const SubscriptionContext = React.createContext({} as SubscriptionContextState);
const Account = props => {
  const { data } = props;
  const { allRestApiApiProducts } = data;
  const allProducts = (allRestApiApiProducts?.nodes || []).map(it => {
    const id = it.endpointId;
    // delete it.endpointId;
    return { ...it, id };
  });
  const [contextState, setContext] = useState(initialState);
  const [showPageLoader, setShowPageLoader] = useState(true);

  // const petSubscriptionMap = contextState.subscriptions.reduce(
  //   (prev, subscription) => {
  //     if (subscription.pet) {
  //       const petId = subscription.pet.match(/\/(\d+)\/$/)[1];
  //       prev[petId] = subscription || {};
  //     }
  //     return prev;
  //   },
  //   {},
  // );

  const petSubscriptionMap = Array.isArray(contextState.subscriptions)
    ? contextState.subscriptions.reduce((prev, subscription) => {
      if (subscription.pet) {
        const match = subscription.pet.match(/\/(\d+)\/$/);
        if (match && match[1]) {
          const petId = match[1];
          prev[petId] = subscription || {};
        }
      }
      return prev;
    }, {})
    : {};

  const setContextState = (state: Partial<SubscriptionContextState>) => {
    setContext({ ...contextState, ...state });
  };

  useEffect(() => {
    isLoggedIn() &&
      (async () => {
        try {
          setContextState({ isContextLoaded: false });
          const [
            pets,
            subscriptions,
            productPrice,
            allProductsResponse,
          ] = await Promise.all([
            getAllPets(),
            getUserSubscription(),
            getProductPrice(),
            getProducts(),
          ]);

          setContextState({
            subscriptions,
            pets,
            productPrice: parseFloat(productPrice.incl_tax),
            isContextLoaded: true,
            selectedSubscription: subscriptions?.[0] || null,
            allProducts: allProductsResponse.data || [],
          });
        } catch (error) {
          console.error("Error loading data:", error);
        }
      })();
  }, []);

  const fetchSubscriptions = async () => {
    // const subscriptions = await getUserSubscription();
    try {
      const subscriptions = (await getUserSubscription()) || [];
      setContextState({ subscriptions });
      return subscriptions;
    } catch (error) {
      console.error("Failed to fetch subscriptions:", error);
      setContextState({ subscriptions: [] });
      return [];
    }
  };
  const fetchPets = async () => {
    const pets = await getAllPets();
    setContextState({ pets });
    return pets;
  };
  const cancelSubscriptionFunc = async (id, payload) => {
    const pet = getPetBySubscriptionId(id);
    const subscription = await cancelSubscription(id, payload, pet?.id);
    const remainingSubscriptions = getRemainingSubscriptions(id);

    setContextState({
      subscriptions: remainingSubscriptions,
    });
  };
  const getSubscriptionById = (id: string) => {
    const { subscriptions } = contextState;
    return subscriptions.find(
      subscription => subscription.id === parseInt(id, 10),
    );
  };

  const getRemainingSubscriptions = (id: string) => {
    const { subscriptions } = contextState;
    return subscriptions.filter(
      subscription => subscription.id !== parseInt(id, 10),
    );
  };

  const getRemainingPets = (id: string) => {
    const { pets } = contextState;
    return pets.filter(pet => pet.id !== parseInt(id, 10));
  };

  const getSubscriptionByPetId = petId => petSubscriptionMap[petId];

  const getPetBySubscriptionId = id => {
    const subscription = getSubscriptionById(id);
    const petId = subscription?.pet?.match(/\/(\d+)\/$/)[1];
    return petId ? getPetById(petId) : ({} as Pet);
  };

  const getPetById = (id: string): Pet => {
    const { pets } = contextState;
    return pets.find(pets => pets.id === parseInt(id, 10));
  };

  const resumeSubscription = async (nextOrderDate, subscriptionId, petId) => {
    await updateSubscriptionData(
      {
        is_active: true,
        paused_with_duration: false,
        next_order: formatDate(nextOrderDate || new Date(), 'yyyy-mm-dd'),
      },
      subscriptionId,
      petId,
    );
  };

  const updatePetData = async (petId, data) => {
    const updatedPet = await updatePet(petId, data);
    const remainingPets = getRemainingPets(petId);
    setContextState({
      pets: [updatedPet, ...remainingPets],
    });
    return updatedPet;
  };

  const addPet = async data => {
    const newPet = await createPet(data);
    const { pets } = contextState;
    setContextState({
      pets: [newPet, ...pets],
    });
    return newPet;
  };

  const removePet = async petId => {
    await deletePet(petId);
    const pets = getRemainingPets(petId);
    setContextState({
      pets,
    });
  };

  const updateSubscriptionData = async (data, subscriptionId, petId) => {
    const remainingSubscriptions = getRemainingSubscriptions(subscriptionId);
    const subscription = getSubscriptionById(subscriptionId);
    const updatedSubscription = await updateSubscription(
      subscriptionId,
      {
        frequency: subscription.frequency,
        ...data,
      },
      petId,
    );

    setContextState({
      subscriptions: [updatedSubscription, ...remainingSubscriptions],
    });

    return updatedSubscription;
  };

  return (
    <LoaderContext.Provider value={{ showPageLoader, setShowPageLoader }}>
      <SubscriptionContext.Provider
        value={{
          subscriptions: contextState.subscriptions,
          pets: contextState.pets,
          isContextLoaded: contextState.isContextLoaded,
          productPrice: contextState.productPrice,
          resumeSubscription: resumeSubscription,
          fetchSubscriptions: fetchSubscriptions,
          fetchPets: fetchPets,
          updateSubscriptionData: updateSubscriptionData,
          cancelSubscriptionFunc: cancelSubscriptionFunc,
          getSubscriptionById: getSubscriptionById,
          getSubscriptionByPetId: getSubscriptionByPetId,
          getPetBySubscriptionId: getPetBySubscriptionId,
          getPetById: getPetById,
          updatePetData: updatePetData,
          addPet: addPet,
          removePet: removePet,
          allProducts: contextState.allProducts,
        }}
      >
        <AccountLayout>
          <TitleComponent />
          <Router>
            <PrivateRoute path="/account/dashboard/" Component={DashboardHome}>
              <LoadAfterContext
                canLoad={contextState.isContextLoaded}
                Component={Dashboard}
                path="/"
                allProducts={allProducts || []}
              />
              <LoadAfterContext
                canLoad={contextState.isContextLoaded}
                Component={EditPet}
                path="editpet/:petId"
              />
              <AddPet path="addpet" allProducts={allProducts || []} />
              <LoadAfterContext
                canLoad={contextState.isContextLoaded}
                Component={ManageDeliveries}
                path="managedelivery/:subscriptionId"
              />
              <LoadAfterContext
                canLoad={contextState.isContextLoaded}
                Component={ManageSubscription}
                path="managesubscription/:subscriptionId"
              />
            </PrivateRoute>
            <PrivateRoute path="/account/orders" Component={Orders} />
            <PrivateRoute path="/account/info" Component={AccountInfo} />
            <PrivateRoute path="/account/refer" Component={Refer} />
            <ResetPasswordConfirm path="/account/password/reset/confirm/:uid/:token" />
          </Router>
        </AccountLayout>
      </SubscriptionContext.Provider>
    </LoaderContext.Provider>
  );
};

export const useSubscriptionContext = (): SubscriptionContextState => {
  return React.useContext(SubscriptionContext);
};

export const usePageLoaderContext = (): LoaderContextState => {
  return React.useContext(LoaderContext);
};

export default Account;
export const query = graphql`
  query {
    allRestApiApiProducts {
      nodes {
        title
        id
        endpointId
        image
        is_public
        is_subscribable
        is_suggestible
        items_in_cart_limit
        percentage
        price {
          currency
          excl_tax
          incl_tax
          tax
        }
        product_attributes {
          code
          name
          value
        }
        product_class
        product_type
        shipping_charge
        slug
        subscription_price
        weightage
        url
        description
        categories {
          id
          name
          slug
          ancestors {
            id
            name
            slug
          }
          descendants {
            id
            name
            slug
          }
        }
      }
      totalCount
    }
  }
`;
